import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {InfoModalComponent} from '../info-modal/info-modal.component';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss']
})
export class ToolbarLogoComponent {
	@Input() showCross = false;
	@Input() showDailogNavigate = false;
	@Input() order: Order;
	@Output() close = new EventEmitter<void>();
	@Output() basket = new EventEmitter<void>();
	environment = environment;
	constructor(
		private router: Router,
		public repository: RepositoryService,
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}
	async home() {
		HomePage.navigate(this.router, this.repository);
	}
	closeWindow() {
		this.close.emit();
	}

	basketClick() {
		this.basket.emit();
	}
}
