import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {DisplayIdentifier} from 'src/smoothr-web-app-core/enums/DisplayIdentifier';
import {TranslateService} from '@ngx-translate/core';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number = 0;
	ot = OrderType;
	environment = environment;
	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadPrice();
	}
	priceText;
	numberToCurrency = numberToCurrency;

	constructor(private translate: TranslateService) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}
	@Input() preorderType: PreorderType;

	private reloadPrice() {
		if (this.article && this.preorderType && this._order) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups.push(
				...defaultsToArticleOption(
					this.article,
					[],
					this.article.defaults,
					this.preorderType
				)
			);
			articleGroup.quantity = 1;
			this.priceText = numberToCurrency(
				OrderUtils.totalPrice(
					articleGroup,
					this._order.type,
					this._order?.preorder?.type
				),
				this._order.currency
			);
			return;
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
	}
	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		const indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (
				page.length > 0 &&
				articleGroup.article.recommendations.find(
					recommendation => recommendation.group === page[0]._id
				)
			) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	isTagPriceFrom() {
		return this.article &&
			this.article?.tags?.length > 0 &&
			this.article?.tags?.filter(tag => tag?.identifier === 'fromPrice')
			? this.translate.instant('item_view.price_from')
			: '';
	}
}
