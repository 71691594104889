import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router, ActivatedRoute} from '@angular/router';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {MapPage} from '../map/map.page';
import {TranslateService} from '@ngx-translate/core';
import {MenuPage} from '../menu/menu.page';
import {sleep, venueAcceptsOrders} from 'src/smoothr-web-app-core/utils/utils';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Address from 'src/smoothr-web-app-core/models/Address';
import {MenuModalComponent} from 'src/app/components/menu-modal/menu-modal.component';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.piraten_burger.html',
	styleUrls: ['./home.page.piraten_burger.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/:venueId/:tableNumber';

	environment = environment;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	languageEnum = Language;

	loading = false;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		private snackbarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
		this.route.paramMap.subscribe(params => {
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(
					params.get('tableNumber'),
					params.get('venueId')
				);
			}
		});
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
		// this.repository.previousPreorder.emit(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.emit(null);
		this.repository.payment.emit(null);
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				console.log(venues);
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						console.log(venue);
						this.active[preorderType] =
							this.active[preorderType] ||
							venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		const venue = (await this.repository.getAllVenues()).find(ven =>
			venueAcceptsOrders(ven, preorderType)
		);
		console.log(venue);

		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			this.snackbarCtrl.open(
				this.translate.instant('home_page.service_error'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(
		venue: Venue,
		address: Address,
		preorderType: PreorderType
	) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		console.log(loadedVenue);

		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading = true;
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			if (venueData && table) {
				this.repository.venue.emit(venueData);
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				await MenuPage.navigate(this.router);
				await SelectTableModalComponent.show(this.modalCtrl, this.order);
			}
			this.loading = false;
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('home_page.scan_table_error'),
				null,
				{
					duration: 2000
				}
			);
			this.loading = false;
		}
	}

	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}

	async openMenu() {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal'
		});
		popover.onWillDismiss();
		await sleep(200);
		await popover.present();
		return;
	}
}
