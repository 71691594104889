import {CustomerAuth} from './../../../smoothr-web-app-core/models/CustomerAuth';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {Router} from '@angular/router';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {PopoverController, ModalController} from '@ionic/angular';
import {HomePage} from '../../pages/home/home.page';
import {MyOrdersPage} from '../../pages/my-orders/my-orders.page';
import {AccountPage} from '../../pages/account/account.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import {TosPage} from '../../pages/tos/tos.page';
import {ImpressumPage} from '../../pages/impressum/impressum.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {FaqPage} from 'src/app/pages/faq/faq.page';
import {SignInOrderPage} from 'src/app/pages/sign-in-order/sign-in-order.page';

import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from 'src/smoothr-web-app-core/utils/utils';
import {MenuPage} from 'src/app/pages/menu/menu.page';
import {Api} from 'src/smoothr-web-app-core/api/api';
enum Language {
	GreatBritain = 'en',
	German = 'de'
}
@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['./menu-modal.component.scss']
})
export class MenuModalComponent extends RepositoryDirective implements OnInit {
	environment = environment;
	languageEnum = Language;
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService,
		private modalCtrl: ModalController
	) {
		super(repository);
	}
	ngOnInit() {
		super.ngOnInit();
		console.log(this.authLogin);
	}

	async signIn() {
		await this.modalController.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.modalController.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.modalController.dismiss();
		await AccountPage.navigate(this.router);
	}

	async myOrders() {
		await this.modalController.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			if (this.customer) {
				this.repository.customerAuth.emit(null);
			}
			if (this.authLogin) {
				this.repository.authLogin.emit(null);
			}
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_success'),
				null,
				{
					duration: 2000
				}
			);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_error'),
				null,
				{
					duration: 2000
				}
			);
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await HomePage.navigate(this.router);
	}

	async privacy() {
		await this.modalController.dismiss();
		await PrivacyPage.navigate(this.router);
	}

	async tos() {
		await this.modalController.dismiss();
		await TosPage.navigate(this.router);
	}

	async impressum() {
		await this.modalController.dismiss();
		ImpressumPage.navigate(this.router);
	}
	async allergens() {
		window.open('https://maloa.com/naehrwerte/', '_blank');
		await this.modalController.dismiss();
		// TODO open PDF
	}
	async faq() {
		await this.modalController.dismiss();
		FaqPage.navigate(this.router);
	}
	async signInEmployee() {
		await this.modalController.dismiss();
		SignInOrderPage.navigate(this.router);
	}

	dismiss() {
		this.modalController.dismiss();
	}
	async MenuPage() {
		await this.modalController.dismiss();
		MenuPage.navigate(this.router);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async openHomePage() {
		this.modalController.dismiss();
		window.open('https://maloa.com/', '_blank');
	}
}
