import {environment} from './../../../environments/environment.prod';
import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MenuModalComponent} from '../menu-modal/menu-modal.component';
import {sleep} from './../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-burger-btn',
	templateUrl: './burger-btn.component.html',
	styleUrls: ['burger-btn.component.scss']
})
export class BurgerBtnComponent {
	environment = environment;
	constructor(private modalCtrl: ModalController) {}

	async presentPopover(ev: any) {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal'
		});
		popover.onWillDismiss();
		await sleep(200);
		await popover.present();
		return;
	}
}
