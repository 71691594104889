import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const customerGroup = 'piraten_burger';

const supportEmail = {
	piraten_burger: 'piraten_burger@smoothr.de'
};
const firebaseConfig = {
	piraten_burger: {
		apiKey: 'AIzaSyA8LD23b6Cz3zrGtEGl2B8gQOCJWGpS6-c',
		authDomain: 'piraten-burger.firebaseapp.com',
		projectId: 'piraten-burger',
		storageBucket: 'piraten-burger.appspot.com',
		messagingSenderId: '1032994117991',
		appId: '1:1032994117991:web:6718ff60687a8f98a7f7c2'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCHNC0xEc1toWEBpTmvvinDNGy3qYvvR80',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
