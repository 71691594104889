import {Component, EventEmitter, Input, Output} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {environment} from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import {
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {DisplayIdentifier} from 'src/smoothr-web-app-core/enums/DisplayIdentifier';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.html',
	styleUrls: ['item-selectable-article.component.scss']
})
export class ItemSelectableArticleComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Input() currency: string;
	numberToCurrency = numberToCurrency;

	constructor() {}

	get price(): number {
		let price = getPrice(this.article, OrderType.PREORDER, this.preorderType);
		if (
			this.group &&
			this.group.displayIdentifiers.includes(DisplayIdentifier.sides)
		) {
			const minPriceOfGroup = Math.min(
				...this.group.articles.map(art =>
					art.price && numberD(art.price) ? numberD(art.price) : 0
				)
			);
			return price - minPriceOfGroup;
		}
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}
}
