import {PreorderType} from './../../../smoothr-web-app-core/enums/PreorderType';
import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-order-page-toolbar',
	templateUrl: './order-page-toolbar.component.html',
	styleUrls: ['order-page-toolbar.component.scss']
})
export class OrderPageToolbarComponent implements OnInit {
	@Output()
	backClick = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Output()
	logo = new EventEmitter<void>();
	@Input()
	showBack = true;
	@Input() basketShow = true;

	@Input() title: string;

	environment = environment;
	pt = PreorderType;
	constructor() {}

	ngOnInit() {}

	onBackClick() {
		this.backClick.emit();
	}
	onClose() {
		this.close.emit();
	}
	logoClick() {
		this.logo.emit();
	}
	openSelectTableModal() {}
}
